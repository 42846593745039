<template>
  <h2>1. Game Description</h2>
  <p>
    The Highlight Games NBA Last 60 game is a unique software simulation of randomly selected basketball highlights
    from matches to create gaming opportunities. 
  </p>
  <h2>2. Game Description and Length</h2>
  <p>
    NBA Last 60 Scheduled contains a series of pages that will be time driven, allowing the end user to inform
    themselves about important game facts and bet market opportunities. Each game in NBA Last 60 is approximately 3
    minutes long from preamble to final result.
  </p>
  <p>
    A selection of highlight NBA events is statistically independent, random and unpredictable, and generated by the
    Random Number Generator – RNG.
  </p>
  <h2>3. Betting Markets</h2>
  <h3>3.1 Match Winner</h3>
  <p>
    A Player can bet on the overall match winner based on both the starting score line and the last 60 score line added
    together.   The overall score line can never be a draw in NBA Last 60.
  </p>
  <h3>3.2 Game Winner</h3>
  <p>Bet on the winning team of the score line in the last 60. This last 60 score line can never be a draw.  </p>
  <h3>3.3 Both Teams Over 2.5 Points</h3>
  <p>
    Player can bet on Both Home and Away teams to score at least over 2.5 points each in a single game of NBA Last 60.
  </p>
  <h3>3.4 Both Team Over 3.5 Points</h3>
  <p>Both Home and Away teams need to score at least over 3.5 points each in a single game of NBA Last 60.</p>
  <h3>3.5 Total 3-Pointers</h3>
  <p>
    Predict the total amount of 3-pointers score in the game, this can be combined so if either the home or away team
    scores a 3-pointer it will count towards the total number of 3-pointers. The Maximum number of 3-pointers in a game
    can be 6.
  </p>
  <h3>3.6 First Team to Score</h3>
  <p>The first team home or away to score a point in NBA Last 60.</p>
  <h3>3.7 Winning Margin</h3>
  <p>
    Predict the winning margin by the winning team in the Last 60 game, Winning Margin examples: 1-3, 4-6, 7-9. 10-12 or
    More than 12 points.
  </p>
  <h3>3.8 First Team to 4 Points</h3>
  <p>
    The first team Home or Away to score 4 points in the last 60 game. The “Neither Team” outcome is not valid selection
    for this market.  
  </p>
  <h3>3.9 Team 1 Over/Under 4.5 Points</h3>
  <p>The points scored by Team 1 (Home) in Last 60 will be over or under 4.5.</p>
  <h3>3.10 Team 2 Over/Under 4.5 Points</h3>
  <p>The points scored by Team 2 (Away) in Last 60 will be over or under 4.5.</p>
  <h3>3.11 Team 1 Over/Under 5.5 Points</h3>
  <p>The points scored by Team 1 (Home) in Last 60 will be over or under 5.5.</p>
  <h3>3.12 Team 2 Over/Under 5.5 Points</h3>
  <p>The points scored by Team 2 (Away) in Last 60 will be over or under 5.5.</p>
  <h3>3.13 Game Winner &amp; Total Points Over/Under 11.5</h3>
  <p>
    Predict the game winner of the Last 60 as well as if the total points scored in the Last 60 will be over or under
    11.5 points as a combination bet. (This market is fixed to 11.5 and does not have variable values as with some of
    the markets above.)  
  </p>
  <h3>3.14 Game Winner &amp; Winning Margin</h3>
  <p>Predict the game winner of the Last 60 as well as the winning margin, so by how many points that team win by.  </p>
</template>
